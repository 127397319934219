<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Driver certificate information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      v-model="driverCertificate.title"
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      :rules="[v => Boolean(v) || 'Please provide a title']"
                      label="Title"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="driverCertificate.priority"
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        :rules="[v => Boolean(v) || 'Please provide a priority']"
                        type="number"
                        label="Priority"
                      />
                    </InputHotKeyWrapper>
                  </v-flex>
                  <v-flex
                    xs12>
                    <v-textarea
                      v-model="driverCertificate.description"
                      label="Description"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      class="custom-file-input"
                      multiple
                      accept="image/*"
                      @change="previewFiles">
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <div
                      v-if="driverCertificate.imageData != null && driverCertificate.imageData.length > 0"
                      class="image-preview column-layout justify-center align-center">
                      <img
                        :src="driverCertificate.imageData"
                        class="preview">
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Request from '../../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'

export default {
  mixins: [Request],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      driverCertificate: {},
      setNewImage: false
    }
  },
  beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      this.request('GET', `/driver-certificates/${this.$route.params.id}`, {}, ({ data }) => {
        this.driverCertificate = data
        if (data.icon != null) this.driverCertificate.imageData = data.icon.url
      }, (loader) => { this.loading = loader })
    },
    previewFiles (event) {
      this.files = this.$refs.myFiles.files

      var input = event.target

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.driverCertificate.imageData = e.target.result
          this.driverCertificate.imageName = input.files[0].name
          this.setNewImage = true
          this.$forceUpdate()
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    async saveDriverCertificateIcon (reference, file) {
      return new Promise((resolve) => {
        let body = {
          data: {
            'name': this.driverCertificate.imageName,
            'content': file,
            'type': 'driver-certificate-icon',
            'reference': reference
          }
        }
        this.request('POST', '/files', body, ({ data }) => {
          this.setNewImage = false
          resolve(1)
        })
      })
    },
    saveFunction () {
      let body = {
        data: {
          'title': this.driverCertificate.title,
          'description': this.driverCertificate.description,
          'priority': this.driverCertificate.priority
        }
      }

      this.request('PATCH', `/driver-certificates/${this.$route.params.id}`, body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Driver certificate is updated'
        })

        if (this.driverCertificate.imageData != null && this.setNewImage) {
          let imageData = this.driverCertificate.imageData.replace(/^data:image.+;base64,/, '')

          this.saveDriverCertificateIcon(data.id, imageData).then(() => {
            this.$router.push('/tables/driver-certificates')
          })
        } else {
          this.$router.push('/tables/driver-certificates')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview {
    max-width: 100%;
  }
</style>
